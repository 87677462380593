import { Typography } from "@material-ui/core";
import React from "react";

function ListingPage(props) {
  return (
    <div>
      <Typography>soon...</Typography>

      {props.match.params.id}
    </div>
  );
}

export default ListingPage;
