import React from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  connectPagination,
  RefinementList,
  connectSearchBox,
  connectHitsPerPage,
  connectInfiniteHits,
} from "react-instantsearch-dom";
import { findResultsState } from "react-instantsearch-dom/server";
import {
  Avatar,
  Button,
  Divider,
  Input,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/system";
import {
  ArrowLeftSharp,
  ArrowRight,
  ArrowRightAltSharp,
  GTranslate,
  KeyboardArrowLeftSharp,
  KeyboardArrowRightSharp,
  Restore,
} from "@material-ui/icons";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPID,
  process.env.REACT_APP_ALGOLIA_APIKEY
);

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
  <form noValidate action="" role="search">
    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
      <TextField
        size="small"
        style={{ width: 400, border: "1px solid #eee", borderRadius: 5 }}
        variant="outlined"
        label="Search listings"
        value={currentRefinement}
        onChange={(e) => {
          refine(e.target.value);
        }}
      />
      <Button
        disableElevation={true}
        color="primary"
        variant="contained"
        onClick={() => refine("")}
        startIcon={<Restore />}
      >
        Reset
      </Button>
    </Stack>
    {isSearchStalled ? "My search is stalled" : ""}
  </form>
);
const CustomSearchBox = connectSearchBox(SearchBox);

const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => (
  <div style={{ textAlign: "right" }}>
    <Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end" }}>
      <Typography>Results per page : </Typography>
      {items.map((item) => (
        <a
          href={createURL(item.value)}
          style={{
            color: item.isRefined ? "white" : "black",
            textDecoration: "none",
            fontWeight: item.isRefined ? "bold" : "",
            backgroundColor: item.isRefined ? "#394a60" : "#eee",
            padding: 5,
            borderRadius: 10,
            textAlign: "center",
            width: 20,
          }}
          onClick={(event) => {
            event.preventDefault();
            refine(item.value);
          }}
        >
          {item.label}
        </a>
      ))}
    </Stack>
  </div>
);

const CustomHitsPerPage = connectHitsPerPage(HitsPerPage);

const InfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
}) => {
  return (
    <div>
      {hits.map((hit) => (
        <Link href={`/listing/${hit.listingId}`}>
          <Stack direction="row" style={{ margin: 10 }} spacing={2}>
            <Avatar
              src={hit.images[0].url}
              variant="square"
              style={{ height: 70, width: 200, borderRadius: "10px" }}
            />
            <Stack direction="column">
              <Typography>
                <strong>{hit.title}</strong>
              </Typography>
              <small>{hit.address}</small>
            </Stack>
            <Stack></Stack>
          </Stack>
        </Link>
      ))}
      <Stack
        direction="row"
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Button
          disabled={!hasPrevious}
          onClick={refinePrevious}
          startIcon={<KeyboardArrowLeftSharp />}
        >
          previous
        </Button>
        <Button
          disabled={!hasMore}
          onClick={refineNext}
          endIcon={<KeyboardArrowRightSharp />}
        >
          more
        </Button>
      </Stack>
    </div>
  );
};

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
  <Stack
    spacing={1}
    direction="row"
    sx={{ justifyContent: "center", alignItems: "center" }}
  >
    {new Array(nbPages).fill(null).map((_, index) => {
      const page = index + 1;
      const style = {
        fontWeight: currentRefinement === page ? "bold" : "",
        width: 20,
        padding: 5,
        backgroundColor: currentRefinement === page ? "#394a60" : "#eee",
        borderRadius: 10,
        textDecoration: "none",
        color: currentRefinement === page ? "#fff" : "#000",
        textAlign: "center",
      };

      return (
        <a
          href={createURL(page)}
          style={style}
          onClick={(event) => {
            event.preventDefault();
            refine(page);
          }}
        >
          {page}
        </a>
      );
    })}
  </Stack>
);

const CustomPagination = connectPagination(Pagination);

const SearchGeneral = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName="published_listings">
      <Stack direction="column" spacing={2}>
        <CustomSearchBox
          searchAsYouType={true}
          showLoadingIndicator={true}
          defaultRefinement={""}
        />
        <Divider />
      </Stack>

      <RefinementList attribute="workspaceTypes" />
      <CustomInfiniteHits />
      <CustomPagination />
      <Divider style={{ margin: 10 }} />
      <CustomHitsPerPage
        defaultRefinement={5}
        items={[
          { value: 5, label: "5" },
          { value: 10, label: "10" },
        ]}
      />
    </InstantSearch>
  );
};

export default SearchGeneral;
