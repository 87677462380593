import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListingReviewList from "../../components/Listings/ListingReview/ListingReviewList";
import { getListingsUnderReview } from "../../redux/actions/listingActions";
import DrawerContainer from "../Home/DrawerContainer";

function ListingReview({ Height }) {
  const dispatch = useDispatch();
  const listings = useSelector((state) => state.listing);

  useEffect(() => {
    dispatch(getListingsUnderReview());
  }, []);

  return (
    <Grid container spacing={1}>
      <ListingReviewList
        listings={listings.listing_under_review}
        Height={Height}
      />
    </Grid>
  );
}

export default ListingReview;
