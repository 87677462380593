import { Button, Card, CardContent, CardHeader } from "@material-ui/core";
import { Stack } from "@mui/system";
import React from "react";

function ListingReviewHeader({ onApprove, onReject, onSubmitReviews }) {
  return (
    <Card>
      <CardHeader title="Quick Actions"></CardHeader>
      <CardContent>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              onApprove();
            }}
            color="primary"
            variant="contained"
          >
            Approve
          </Button>
          <Button
            onClick={() => {
              onSubmitReviews();
            }}
            color="secondary"
            variant="contained"
          >
            Preview and Send comments
          </Button>
          <Stack direction="column">
            <Button
              onClick={() => {
                onReject();
              }}
              variant="outlined"
              color="primary"
              style={{ color: "red" }}
            >
              Reject and delete
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ListingReviewHeader;
