import axios from "axios";
import React, { useEffect, useState } from "react";

function useGetListingOwner(userId) {
  const [LoadingOwner, setLoadingOwner] = useState(true);
  const [Owner, setOwner] = useState({});

  useEffect(() => {
    setLoadingOwner(true);
    axios
      .post(
        // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/getListingOwner",
        "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/getListingOwner",

        { userId }
      )
      .then((data) => {
        setOwner(data.data);
        setLoadingOwner(false);
      })
      .catch(() => {
        setOwner(null);
        setLoadingOwner(false);
      });
  }, []);

  return { Owner, LoadingOwner };
}

export default useGetListingOwner;
