import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./fullScreen.css";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import jwtDecode from "jwt-decode";

// Redux
import { Provider } from "react-redux";

import store from "./redux/store";
import { SET_AUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userActions";
// Components
import themeObject from "./util/theme";
// Pages

import axios from "axios";
// import firebaseConfig from "./util/firebaseConfig";

import Routers from "./Routers";

const theme = createTheme(themeObject);

function App() {
  useEffect(() => {
    const token = localStorage.getItem("FBIdToken");

    if (token) {
      const decodedToken = jwtDecode(token);

      if (decodedToken.exp * 1000 < Date.now()) {
        store.dispatch(logoutUser());
        window.location.href = "/login";
      } else {
        store.dispatch({ type: SET_AUTHENTICATED });
        axios.defaults.headers.common["Authorization"] = token;
        store.dispatch(getUserData());
      }
    } else {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Routers />
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
