import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { login } from "./pages/Authentication";
import Home from "./pages/Home/Home";
import AuthRoute from "./util/AuthRoute";

import {
  AllListings,
  ListingReview,
  ListingReviewPage,
} from "./pages/Listings";
import DrawerContainer from "./pages/Home/DrawerContainer";
import Promotions from "./pages/Promotions/Promotions";
import Categories from "./pages/Categories/Categories";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import ListingPage from "./pages/ListingPage/ListingPage";

function Routers() {
  const user = useSelector((state) => state.user);
  return (
    <>
      {user.loading ? (
        <CircularProgress />
      ) : !user.authenticated ? (
        <Router>
          <Switch>
            <AuthRoute exact path="/login" component={login} />
          </Switch>
        </Router>
      ) : (
        <Router>
          <Switch>
            <AuthRoute exact path="/login" component={login} />
            <DrawerContainer>
              <Route exact path="/" component={Home} />
              <Route exact path="/listings/all" component={AllListings} />
              <Route exact path="/listing/:id" component={ListingPage} />

              <Route
                exact
                path="/listings/to_review"
                component={ListingReview}
              />
              <Route
                exact
                path="/listing/to_review/:id"
                component={ListingReviewPage}
              />
              <Route exact path="/listings/analysis" component={Home} />
              <Route
                exact
                path="/homescreen/promotions"
                component={Promotions}
              />
              <Route exact path="/listings/categories" component={Categories} />
            </DrawerContainer>
          </Switch>
        </Router>
      )}
    </>
  );
}

export default Routers;
