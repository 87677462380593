import axios from "axios";

const updateReviewCommentsData = async (listingId, comments) => {
  console.log("Started the function");
  const result = new Promise((resolve, reject) => {
    axios
      .post(
        // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/listing/under_review/addReviewComments",
        "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/listing/under_review/addReviewComments",

        { listingId, ...comments }
      )
      .then((d) => {
        console.log("DATA SAVED .. ");
        resolve("saved");
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { updateReviewCommentsData };
