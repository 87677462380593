import {
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListings } from "../../redux/actions/listingActions";
import { useTheme } from "@emotion/react";
import { ListingReview } from "../Listings";
import SearchGeneral from "../../components/General/SearchGeneral";

function Home({ Locations }) {
  const user = useSelector((state) => state.user);
  const listing = useSelector((state) => state.listing);
  const dispatch = useDispatch();
  const [Url, setUrl] = useState("/");
  const [Open, setOpen] = useState(true);
  const theme = useTheme();
  useEffect(() => {
    dispatch(getListings());
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
        <Typography variant="h5" style={{ margin: 5 }}>
          To review
        </Typography>
        <ListingReview Height={"500px"} />
      </Grid>
      <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
        <Typography variant="h5" style={{ margin: 5 }}>
          All listings
        </Typography>
        <SearchGeneral />
      </Grid>
    </Grid>
  );
}

export default Home;
