import { Stack } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";

function ListingReviewSchedule({ Listing }) {
  const [DaysTimes, setDaysTimes] = useState({});
  useEffect(() => {
    let days_times = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };
    if (Listing && Listing.daysTimes) {
      console.log(Listing.daysTimes);
      for (const i in Listing.daysTimes) {
        if (Object.keys(Listing.daysTimes[i]).includes("days")) {
          Listing.daysTimes[i].days.forEach((d) => {
            days_times[d] = Listing.daysTimes[i].times;
          });
        }
        setDaysTimes((prev) => {
          return { ...prev, ...days_times };
        });
      }
    }
    console.log(days_times);
  }, [Listing]);

  return (
    <Stack direction="column" spacing={1}>
      {DaysTimes &&
        Object.keys(DaysTimes).map((i) => (
          <Stack direction="row" spacing={1} sx={{ width: "80%" }}>
            <div style={{ width: 200 }}>
              -{" "}
              {moment()
                .weekday(i)
                .format("dddd")}
            </div>
            <div>
              <strong>
                {moment()
                  .startOf("day")
                  .add(DaysTimes[i][0] * 30, "minutes")
                  .format("HH:mm")}
              </strong>
            </div>
            <div style={{ width: 50, textAlign: "center" }}> to: </div>
            <div>
              <strong>
                {moment()
                  .startOf("day")
                  .add(DaysTimes[i][1] * 30, "minutes")
                  .format("HH:mm")}
              </strong>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#eee",
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  padding: 5,
                  width: `${(DaysTimes[i][1] - DaysTimes[i][0]) / 0.48 ||
                    "5"}%`,
                  backgroundColor: "#394a60",
                  borderRadius: 5,
                  color: "white",
                  //   fontSize: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {`${(DaysTimes[i][1] - DaysTimes[i][0]) / 2 || "0"}h`}
              </div>
            </div>
          </Stack>
        ))}
    </Stack>
  );
}

export default ListingReviewSchedule;
