import {
  Button,
  Card,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Alert } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { updateReviewCommentsData } from "../../util/LisitngReview/ListingReviewUtils";

function ListingReviewSection({
  Listing,
  Loading,
  title,
  key,
  content,
  criteria,
  Comments,
}) {
  const [ReiewComments, setReiewComments] = useState(
    Comments && Comments[title] ? Comments[title] : ""
  );
  const [SavingStatus, setSavingStatus] = useState("");

  const updageListingComments = (comments) => {
    if (ReiewComments.trim() !== "") {
      updateReviewCommentsData(Listing.listingId, {
        [title]: comments,
      })
        .then((st) => {
          setSavingStatus("Saved");
        })
        .catch((error) => {
          console.log(error);
          setSavingStatus("Couldn't save");
        });
    }
  };

  return (
    <Grid item>
      <Card elevation={0} variant="outlined">
        <Stack direction="column" spacing={3} sx={{ p: 3, my: 2 }}>
          <Grid item>
            <Typography color="primary" variant="h4">
              {title}
            </Typography>
            <Alert variant="outlined" severity="info">
              {criteria}
            </Alert>
          </Grid>
          <Stack sx={{ p: 1 }} direction="column" spacing={3}>
            {content}
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid item sm={10} md={10} lg={10} xl={10}>
                <TextField
                  variant="outlined"
                  minRows={3}
                  color="primary"
                  style={{ backgroundColor: "#eee", width: "100%" }}
                  label="Your review comments (leave it blank if no comments)"
                  multiline={true}
                  value={ReiewComments}
                  onChange={(e) => {
                    setReiewComments(e.target.value);
                  }}
                  onBlur={() => {
                    updageListingComments(ReiewComments);
                  }}
                ></TextField>
              </Grid>
              <Grid item sm={2}>
                {SavingStatus !== "" && (
                  <Alert
                    severity={SavingStatus == "Saved" ? "success" : "error"}
                  >
                    {SavingStatus}
                  </Alert>
                )}
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
}

export default ListingReviewSection;
