import axios from "axios";
import React, { useEffect, useState } from "react";

function useGetReviewComments(listingId) {
  const [LoadingComments, setLoadingComments] = useState(true);
  const [Comments, setComments] = useState({});

  useEffect(() => {
    setLoadingComments(true);
    axios
      .post(
        // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/getReviewComments",
        "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/getReviewComments",

        { listingId }
      )
      .then((res) => {
        const result = res.data;
        let com;
        let comments = null;
        if (Object.keys(result).length > 0) {
          com = Object.keys(result).map((i) => {
            return `${i}\n${result[i]}\n`;
          });
          comments = com.join("\n\n");
        } else {
          comments = null;
        }
        return comments;
      })
      .then((comments) => {
        console.log("comments", comments);
        setComments(comments);
        setLoadingComments(false);
        // setComments(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return { Comments, LoadingComments };
}

export default useGetReviewComments;
