import {
  Button,
  createTheme,
  Grid,
  MuiThemeProvider,
  ThemeProvider,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContainer from "../../components/Home/MainContainer";
import { getListings } from "../../redux/actions/listingActions";
import SideBarList from "../../components/Home/SideBarList";
import { config, urlConfig } from "../config";

import themeObject from "../../util/theme";

const theme = createTheme(themeObject);
function DrawerContainer({ children }) {
  const dispatch = useDispatch();
  const [Url, setUrl] = useState("/");
  const [Open, setOpen] = useState(true);

  useEffect(() => {}, []);

  const handleButtonClick = (location) => {
    if (location.includes("https")) {
      window.location.href = location;
    } else {
      setUrl(location);
    }
  };
  return (
    <MuiThemeProvider theme={theme}>
      <MainContainer
        onDrawerOpen={(open) => {
          setOpen(open);
        }}
        list={
          <SideBarList
            list={config}
            open={Open}
            onButtonClick={handleButtonClick}
          />
        }
        body={children} //according to each url.
      />
    </MuiThemeProvider>
  );
}

export default DrawerContainer;
