import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
  Modal,
  Box,
  Avatar,
} from "@material-ui/core";
import Textarea from "@mui/joy/Textarea";
import { Stack } from "@mui/material";
import useGetReviewComments from "../../hooks/useGetReviewComments";
import useGetListingOwner from "../../hooks/useGetListingOwner";
import axios from "axios";
function PreviewAndSubmit({ listingId, Listing }) {
  const { Comments, LoadingComments } = useGetReviewComments(listingId);
  const { Owner, LoadingOwner } = useGetListingOwner(Listing.userId);
  const [FinalComments, setFinalComments] = useState(Comments);
  const [LoadingGenerl, setLoadingGenerl] = useState(false);

  useEffect(() => {
    let username = !LoadingOwner && Owner.handle;
    let emailTemp = `Dear ${username}, 

Thank you for submitting your listing on SpaceMatch. 
We have finished reviewing your listing. Please kindly address the following comments before publishing it. 
Thank you.

SpaceMatch team.
hello@spacematch.me
----


`;
    setFinalComments(emailTemp + Comments);
  }, [LoadingOwner, LoadingComments]);

  const handleSubmitComments = () => {
    if (FinalComments.trim() !== "") {
      console.log("Started submitting ... ");
      setLoadingGenerl(true);
      //submitComments
      axios
        .post(
          //   "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/submitComments",
          "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/submitComments",
          { owner: Owner, listing: Listing, comments: FinalComments }
        )
        .then((d) => {
          setLoadingGenerl(false);
          alert("Successfully sent");
          window.location.href = "/listings/to_review";
        })
        .catch((error) => {
          setLoadingGenerl(false);
          alert("Something wrong happended");
        });
      console.log(FinalComments);
    } else {
      console.log("Cannot send");
    }
  };
  return (
    <div>
      <Typography variant="h5">Preview comments</Typography>
      <Stack direction="column" spacing={3}>
        {LoadingComments || LoadingOwner || LoadingGenerl ? (
          <CircularProgress size="large" />
        ) : (
          <>
            <Stack spacing={3} direction="column">
              <Stack
                spacing={1}
                direction="column"
                sx={{ justifyContent: "center" }}
              >
                <Typography>
                  <strong>Onwer : </strong>
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <Avatar src={Owner.imageUrl} />
                  <Typography>{Owner.handle}</Typography>
                </Stack>
                <Typography>
                  <strong>Email: </strong>
                  {Owner.email}
                </Typography>
                <Typography>
                  <strong>Phone: </strong>
                  {Owner.phone}
                </Typography>
              </Stack>
              <Textarea
                value={FinalComments}
                onChange={(e) => {
                  setFinalComments(e.target.value);
                }}
              ></Textarea>
            </Stack>
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          disabled={
            !Comments ||
            Object.keys(Comments).length == 0 ||
            FinalComments.trim() === ""
          }
          onClick={() => {
            handleSubmitComments();
          }}
        >
          Submit
        </Button>
      </Stack>
    </div>
  );
}

export default PreviewAndSubmit;
