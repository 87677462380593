import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import moment from "moment";
import { Card } from "@material-ui/core";

const columns = [
  { field: "title", headerName: "Title", flex: 200 },
  { field: "address", headerName: "Address", flex: 100 },
  { field: "createdAt", headerName: "Submitted", flex: 100 },
  { field: "urgency", headerName: "Stauts", flex: 50 },
  // { field: "action", headerName: "Action", flex: 100 },
];

function Urgent() {
  return <Card color="warn">Urgent</Card>;
}

export default function ListingReviewList({ listings, Height }) {
  const [Rows, setRows] = useState([
    {
      id: 0,
      title: "Loading..",
      address: "Loading..",
      createdAt: "Loading..",
      urgency: "Loading ..",
      // action: "Loading..",
    },
  ]);

  useEffect(() => {
    console.log(listings);
    if (listings.length > 0) {
      setRows(
        listings.map((i) => {
          return {
            id: i.listingId,
            title: i.title || "No title",
            address: i.address || "Not set",
            createdAt: moment(i._submittedAt).fromNow(),
            urgency: moment(i._submittedAt).isBefore(
              moment().subtract(48, "hours")
            )
              ? "Urgent"
              : "Not urgent",
          };
        })
      );
    }
    console.log("loaded ..");
  }, [listings.length]);

  return listings.length > 0 ? (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        height: Height || `calc(100vh - 100px)`,
      }}
    >
      <DataGrid
        onCellClick={(d) => {
          window.location.href = `/listing/to_review/${d.id}`;
        }}
        style={{ cursor: "pointer" }}
        rows={Rows}
        columns={columns}
        rowHeight={25}
        // pageSize={50}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </div>
  ) : (
    <div>Loading ...</div>
  );
}
