import React from "react";
import SearchGeneral from "../../components/General/SearchGeneral";
import DrawerContainer from "../Home/DrawerContainer";

function AllListings() {
  return (
    <>
      <SearchGeneral />
    </>
  );
}

export default AllListings;
