import React from "react";
import PropTypes from "prop-types";

import ReactMapboxGl, { Marker } from "react-mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

const mapApiKey = process.env.REACT_APP_MAPBOX;

const Map = ReactMapboxGl({
  accessToken: mapApiKey,
  antialias: true,
  maxZoom: 30,
});

ListingReviewMap.propTypes = {
  data: PropTypes.array,
  flyTo: PropTypes.any,
};

export default function ListingReviewMap({ Listing, flyTo }) {
  return (
    <div>
      <Map
        // eslint-disable-next-line react/style-prop-object
        style={"mapbox://styles/mapbox/light-v10"}
        containerStyle={{
          height: 225,
        }}
        center={
          Listing &&
          Listing._geoloc &&
          Listing._geoloc.lng &&
          Listing._geoloc.lat
            ? [Listing._geoloc.lng, Listing._geoloc.lat]
            : [103.7703383, 1.2968729]
        }
        zoom={Listing ? [10.0] : [13.0]}
        bearing={[0]}
        // pitch={[30]}
        // animationOptions={{
        //   animate: true,
        //   easing: 4.0,
        // }}
      >
        <Marker
          key={Listing.listingId}
          coordinates={
            Listing &&
            Listing._geoloc &&
            Listing._geoloc.lng &&
            Listing._geoloc.lat
              ? [Listing._geoloc.lng, Listing._geoloc.lat]
              : [103.7703383, 1.2968729]
          }
          anchor="bottom"
        >
          <div
            style={{
              backgroundColor: "white",
              padding: 10,
              borderRadius: "10px",
              boxShadow: "0px 0px 10px black",
            }}
          >
            {Listing && Listing.address}
          </div>
        </Marker>
      </Map>
    </div>
  );
}

Map.propTypes = {};
