import {
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  SHOW_OVERLAY,
  OVERLAY_CONTENT, 
  HIDE_OVERLAY,
  LOADING_OVERLAY,
} from '../types';

const initialState = {
  loading: false,
  errors: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false
      };
    case SHOW_OVERLAY:
      return {
        display: true, 
        payload : action.payload
      };
    case HIDE_OVERLAY:
      return {
        display: false,
        payload: []
      };
    case LOADING_OVERLAY:
      return {
        loading:true,
      }
    case OVERLAY_CONTENT: 
    return {
      content: action.data
    }

    default:
      return state;
  }
}
