export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1200,
      lg: 1600,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: "#dae5f3",
      main: "#394a60",
      dark: "#243b53",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff4cc",
      main: "#ffcb05",
      dark: "#ed6940",
      contrastText: "#243b53",
    },
    danger: {
      main: "#ff0000",
    },
  },
  spreadThis: {},
};
