import Book from "@mui/icons-material/Book";
import React from "react";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  Adb,
  Category,
  DashboardOutlined,
  DockSharp,
  FileCopySharp,
  Home,
  HomeOutlined,
  Info,
  PartyModeSharp,
  WrapTextOutlined,
} from "@material-ui/icons";
import PreviewIcon from "@mui/icons-material/Preview";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import GroupIcon from "@mui/icons-material/Group";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { AllListings, ListingReview, ListingReviewPage } from "./Listings";
import ListingsAnalysis from "./Listings/ListingsAnalysis";
import { Dashboard } from "./Dashboard";

export const config = [
  {
    sectionTitle: "",
    items: [
      {
        title: "Home",
        iconClicked: <HomeOutlined style={{ color: "white" }} />,
        iconNonClicked: <HomeOutlined />,

        url: "/",
      },
      {
        title: "HomeScreen Promotions",
        iconClicked: <PartyModeSharp style={{ color: "white" }} />,
        iconNonClicked: <PartyModeSharp />,
        url: "/homescreen/promotions",
      },
    ],
  },
  {
    sectionTitle: "Listings",
    items: [
      {
        title: "All listings",
        iconClicked: <ApartmentIcon style={{ color: "white" }} />,
        iconNonClicked: <ApartmentIcon />,
        url: "/listings/all",
      },
      {
        title: "To review",
        iconClicked: <PreviewIcon style={{ color: "white" }} />,
        iconNonClicked: <PreviewIcon />,
        url: "/listings/to_review",
      },
      // {
      //   title: "Anal",
      //   iconClicked: <AnalyticsIcon style={{ color: "white" }} />,
      //   iconNonClicked: <AnalyticsIcon />,
      //   url: "/listings/analysis",
      // },
      {
        title: "Listing Categories",
        iconClicked: <Category style={{ color: "white" }} />,
        iconNonClicked: <Category />,
        url: "/listings/categories",
      },
    ],
  },
  {
    sectionTitle: "Users",
    items: [
      {
        title: "All users",
        iconClicked: <GroupIcon />,
        iconNonClicked: <GroupIcon />,
        url: "/all_listings",
      },
      {
        title: "Teams",
        iconClicked: <Diversity3Icon />,
        iconNonClicked: <Diversity3Icon />,
        url: "/all_listings",
      },
      {
        title: "Organizations",
        iconClicked: <CorporateFareIcon />,
        iconNonClicked: <CorporateFareIcon />,
        url: "/all_listings",
      },
    ],
  },
  {
    sectionTitle: "Others",
    items: [
      {
        title: "Documentations",
        iconClicked: <FileCopySharp style={{ color: "white" }} />,
        iconNonClicked: <FileCopySharp />,
        url: "https://www.docs.spacematch.me",
      },
      {
        title: "Info",
        iconClicked: <Info style={{ color: "white" }} />,
        iconNonClicked: <Info />,
        url: "info.spacematch.me",
      },
      {
        title: "Blog",
        iconClicked: <WrapTextOutlined style={{ color: "white" }} />,
        iconNonClicked: <WrapTextOutlined />,
        url: "blog.spacematch.me",
      },
    ],
  },
];

export const urlConfig = (url) => {
  console.log("url", url);
  switch (url) {
    case "/listings/all":
      return <AllListings />;
    case "/listings/to_review":
      return <ListingReview />;
    case "/listings/analysis/":
      return <ListingsAnalysis />;
    default:
      return <Dashboard />;
  }

  // "/": <Dashboard />,
  // "/listings/all/": <AllListings />,
  // "/listings/to_review/": <ListingReview />,
  // "/listing/to_review/:id": <ListingReviewPage />,
  // "/listings/analysis/":
};
