import { Card, Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import ListingReviewMap from "./ListingReviewMap";

function ListingReviewAddress({ Listing }) {
  return (
    Listing && (
      <Stack direction="row" spacing={3}>
        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
          <Card variant="outlined" style={{ padding: 10 }}>
            <Stack
              spacing={2}
              direction="column"
              sx={{ justifyContent: "flex-start" }}
            >
              <Typography variant="h6">
                <strong>Address:</strong>
                <br />
                {Listing && Listing.address}
              </Typography>
              <Typography variant="h6">
                <strong>Unit Number:</strong>{" "}
                {(Listing && Listing.unitNumber) || "N.A"}
              </Typography>
              <Typography variant="h6">
                <strong>Level:</strong> {(Listing && Listing.floor) || "N.A"}
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
          <Card variant="outlined" style={{ padding: 10 }}>
            <ListingReviewMap Listing={Listing} />
          </Card>
        </Grid>
      </Stack>
    )
  );
}

export default ListingReviewAddress;
