/* eslint-disable import/no-anonymous-default-export */
import {
  SET_LISTINGS,
  SET_LISTING,
  ADD_ONE_LISTING,
  DELETE_LISTING,
  PUBLISH_LISTING,
  LOADING_LISTING_DATA,
  SET_MY_LISTINGS,
  ADDING_LISTING_DATA,
  SET_LISTING_UNDER_REVIEW,
} from "../types";

const initialState = {
  listings: [],
  listing: {},
  loading: false,
  loaded: false,
  listing_data: {},
  listings_under_review: [],
  listing_under_review: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LISTINGS:
      return {
        ...state,
        listings: action.payload,
        loaded: true,
        loading: false,
      };
    case SET_LISTING_UNDER_REVIEW:
      return {
        ...state,
        listing_under_review: action.payload,
        loaded: true,
        loading: false,
      };
    case SET_LISTING:
      return {
        ...state,
        listing: action.payload,
      };

    case ADD_ONE_LISTING:
      return {
        ...state,
        listing: action.payload,
        loaded: true,
      };

    case SET_MY_LISTINGS:
      return {
        ...state,
        listings: action.payload,
        loaded: true,
        loading: false,
      };
    case DELETE_LISTING:
      let index = state.workspace.findIndex(
        (workspace) => workspace.workspaceId === action.payload
      );
      state.listings.splice(index, 1);
      return {
        ...state,
      };
    case PUBLISH_LISTING:
      return {
        ...state,
        workspace: [action.payload, ...state.listings],
      };
    case LOADING_LISTING_DATA:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case ADDING_LISTING_DATA:
      return {
        ...state,
        loading: false,
        loaded: true,
        listing_data: action.payload,
      };

    default:
      return state;
  }
}
