// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const SHOW_OVERLAY = "SHOW_OVERLAY";
export const HIDE_OVERLAY = "HIDE_OVERLAY";
export const OVERLAY_CONTENT = "OVERLAY_CONTENT";
export const LOADING_OVERLAY = "LOADING_OVERLAY";
// Data reducer types
export const SET_WXPOSTS = "SET_WXPOSTS";
export const SET_WXPOST = "SET_WXPOST";
export const LIKE_WXPOST = "LIKE_WXPOST";
export const UNLIKE_WXPOST = "UNLIKE_WXPOST";
export const DELETE_WXPOST = "DELETE_WXPOST";
export const POST_WXPOST = "POST_WXPOST";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";

// Workspace data
export const SET_LISTINGS = "SET_LISTINGS";
export const SET_LISTING = "SET_LISTING";
export const ADD_ONE_LISTING = "ADD_ONE_LISTING";
export const SET_MY_LISTINGS = "SET_MY_LISTINGS";
export const DELETE_LISTING = "DELETE_LISTING";
export const PUBLISH_LISTING = "PUBLISH_LISTING";
export const LOADING_LISTING_DATA = "LOADING_LISTING_DATA";
export const ADDING_LISTING_DATA = "ADDING_LISTING_DATA";
export const SET_LISTING_UNDER_REVIEW = "SET_LISTING_UNDER_REVIEW";

// export const SET_WORKSPACE = 'SET_WORKSPACE';

//floorplans
export const SET_LEVEL = "SET_LEVEL";
export const EDIT_LEVEL = "EDIT_LEVEL";
export const SET_BUILDING = "SET_BUILDING";
export const UPDATE_BUILDING_DATA = "UPDATE_BUILDING_DATA";
export const LOADING_BUILDING_DATA = "LOADING_BUILDING_DATA";
