import * as React from "react";
import { styled, useTheme, createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import SpaceMatchIcon from "../ui/SpaceMatchIcon";
import {
  Avatar,
  Button,
  MuiThemeProvider,
  ThemeProvider,
} from "@material-ui/core";
import themeObject from "../../util/theme";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 20px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 20px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  backgroundColor: "primary",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "black",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const theme = createTheme(themeObject);

export default function MainContainer({ list, body, onDrawerOpen }) {
  const [open, setOpen] = React.useState(true);
  const user = useSelector((state) => state.user);

  const handleDrawerOpen = () => {
    setOpen(true);
    onDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    onDrawerOpen(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <AppBar
          sx={{ backgroundColor: "#eee" }}
          position="fixed"
          elevation={0}
          open={open}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              color="info"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 10,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ ...(!open && { display: "none" }) }}>
              {/* <SpaceMatchIcon open={open} /> */}
            </div>
            <Stack direction="row" spacing={3} style={{ alignItems: "center" }}>
              <Typography color="#000" noWrap component="div">
                {user.authenticated && user.credentials.handle} (Super Admin)
              </Typography>
              <Avatar
                src={user.authenticated && user.credentials.imageUrl}
              ></Avatar>
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader style={{ backgroundColor: "#eee" }}>
            <SpaceMatchIcon open={open} />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          {/* <Divider /> */}
          {list}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {body}
        </Box>
      </Box>
    </MuiThemeProvider>
  );
}
