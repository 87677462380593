import {
  SET_WXPOSTS,
  LIKE_WXPOST,
  UNLIKE_WXPOST,
  LOADING_DATA,
  DELETE_WXPOST,
  POST_WXPOST,
  SET_WXPOST,
  SUBMIT_COMMENT,
} from "../types";

const initialState = {
  wxposts: [],
  wxpost: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_WXPOSTS:
      return {
        ...state,
        wxposts: action.payload,
        loading: false,
      };
    case SET_WXPOST:
      return {
        ...state,
        wxpost: action.payload,
      };
    case LIKE_WXPOST:
    case UNLIKE_WXPOST:
      let index = state.wxposts.findIndex(
        (wxpost) => wxpost.wxpostId === action.payload.wxpostId
      );
      state.wxposts[index] = action.payload;
      if (state.wxpost.wxpostId === action.payload.wxpostId) {
        state.wxpost = action.payload;
      }
      return {
        ...state,
      };
    case DELETE_WXPOST:
      index = state.wxposts.findIndex(
        (wxpost) => wxpost.wxpostId === action.payload
      );
      state.wxposts.splice(index, 1);
      return {
        ...state,
      };
    case POST_WXPOST:
      return {
        ...state,
        wxposts: [action.payload, ...state.wxposts],
      };
    case SUBMIT_COMMENT:
      return {
        ...state,
        wxpost: {
          ...state.wxpost,
          comments: [action.payload, ...state.wxpost.comments],
        },
      };
    default:
      return state;
  }
}
