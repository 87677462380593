import { createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ListingReviewContainer from "../../components/ListingReview/ListingReviewContainer";
import DrawerContainer from "../Home/DrawerContainer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
});

function ListingReviewPage(props) {
  return <ListingReviewContainer id={props.match.params.id} />;
}

export default ListingReviewPage;
