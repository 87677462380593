import axios from "axios";
import React, { useEffect, useState } from "react";

function useGetListing(listingId) {
  const [LoadingLisitng, setLoadingLisitng] = useState(true);
  const [Listing, setListing] = useState({});
  const [Comments, setComments] = useState({});
  useEffect(() => {
    setLoadingLisitng(true);
    axios
      .post(
        // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/listing/under_review",
        "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/listing/under_review",
        {
          listingId,
        }
      )
      .then((data) => {
        console.log(data.data);
        setListing(data.data.listing);
        setComments(data.data.comments);
        setLoadingLisitng(false);
      });
  }, []);

  return { Listing, LoadingLisitng, Comments };
}

export default useGetListing;
