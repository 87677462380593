import { Grid, Typography } from "@material-ui/core";
import React from "react";
import icon from "../../images/SpaceMatchLogo.png";

function SpaceMatchIcon({ open }) {
  return (
    <Grid
      container
      style={{
        // justifyContent: "center",
        flexDirection: "row",
        textAlign: "left",
      }}
    >
      <img
        alt="SpaceMatch"
        style={{
          height: 40,
          width: 40,
          borderRadius: 10,
          marginLeft: 10,
        }}
        src={`${icon}`}
      />
    </Grid>
  );
}

export default SpaceMatchIcon;
