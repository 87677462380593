import {
  SET_LISTINGS,
  SET_LISTING,
  SET_MY_LISTINGS,
  ADD_ONE_LISTING,
  DELETE_LISTING,
  PUBLISH_LISTING,
  ADDING_LISTING_DATA,
  LOADING_LISTING_DATA,
  SET_LISTING_UNDER_REVIEW,
} from "../types";

import axios from "axios";

//get all LISTINGs
export const getListings = () => (dispatch) => {
  dispatch({ type: LOADING_LISTING_DATA });

  console.log(" Start getting listings. .");
  axios
    .get(
      "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/listings"
      // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/listings"
    )
    .then((results) => {
      console.log(results);
      dispatch({
        type: SET_LISTINGS,
        payload: results.data,
      });
      console.log("HHERE ARE THE LISTINGS", results.data);
    })
    .catch((err) => {
      dispatch({
        type: SET_LISTINGS,
        payload: [],
      });
      console.error(err);
    });
};

//v2/listings/under_review
// get under review listings

//get all LISTINGs
export const getListingsUnderReview = () => (dispatch) => {
  dispatch({ type: LOADING_LISTING_DATA });

  console.log(" Start getting listings. .");
  axios
    .get(
      "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/listings/under_review"
      // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/listings/under_review"
    )
    .then((results) => {
      console.log(results);
      dispatch({
        type: SET_LISTING_UNDER_REVIEW,
        payload: results.data,
      });
      console.log("HHERE ARE THE LISTINGS", results.data);
    })
    .catch((err) => {
      dispatch({
        type: SET_LISTING_UNDER_REVIEW,
        payload: [],
      });
      console.error(err);
    });
};

// get my LISTINGs
export const getMyListings = () => (dispatch) => {
  dispatch({ type: LOADING_LISTING_DATA });
  console.log(" Start getting only my listings. .");
  axios
    .get(
      "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/getMyListings"
    )
    .then((results) => {
      dispatch({
        type: SET_MY_LISTINGS,
        payload: results.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_MY_LISTINGS,
        payload: [],
      });
      console.error(err);
    });
};

// add one LISTING

export const addNewListing = (listingDetails) => (dispatch) => {
  dispatch({ type: ADDING_LISTING_DATA });

  console.log("Start posting");
  axios
    .post(
      "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/listing",
      listingDetails
    )
    .then((res) => {
      return res;
    })
    .then((results) => {
      dispatch({ type: ADD_ONE_LISTING, payload: results.data });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: ADD_ONE_LISTING, payload: [], loaded: false });
    });
};

//get one LISTING  '/listing/:listingId'
export const getOneListing = (listing_id) => (dispatch) => {
  dispatch({ type: LOADING_LISTING_DATA });
  console.log(" Start getting this listing." + listing_id);
  axios
    .get(
      "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/listing/" +
        listing_id
    )
    .then((results) => {
      dispatch({
        type: SET_LISTING,
        payload: results.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_LISTING,
        payload: [],
      });
      console.error(err);
    });
};

//delete a LISTING

//upload LISTING image
export const uploadListingImage = (formData, config) => (dispatch) => {
  dispatch({ type: LOADING_LISTING_DATA });
  axios
    .post(
      "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/listing/addImage",
      formData,
      config
    )
    .then((results) => {
      // dispatch({type:ADD_ONE_LISTING,
      //   payload: results.data})

      console.log(results.data);
      dispatch({
        type: ADDING_LISTING_DATA,
        payload: results.data,
      });
    })
    .catch((err) => console.error(err));
};
//edit LISTING
