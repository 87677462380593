import {
  SET_BUILDING,
  SET_LEVEL,
  EDIT_LEVEL,
  UPDATE_BUILDING_DATA,
  LOADING_BUILDING_DATA,
} from "../types";

const initialState = {
  buildingSpatialData: {},
  selectedFloorPlan: "0",
  loading: true,
  loaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BUILDING:
      return {
        ...state,
        buildingSpatialData: action.payload,
        loaded: true,
        loading: false,
      };

    case SET_LEVEL:
      return {
        ...state,
        selectedFloorPlan: action.payload,
      };

    case UPDATE_BUILDING_DATA:
      return {
        ...state,
        loaded: true,
        loading: false,
      };

    case LOADING_BUILDING_DATA:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    default:
      return state;
  }
}
