import {
  Button,
  Card,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Alert } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { updateReviewCommentsData } from "../../util/LisitngReview/ListingReviewUtils";

function ListingReviewTitleDescription({ Listing }) {
  return (
    <>
      <Grid item>
        <Typography variant="h5">
          <strong>Title:</strong>
        </Typography>
        <Typography variant="h5">{Listing.title || "No title . "}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          <strong>Description:</strong>
        </Typography>
        <Typography variant="h5">
          {Listing.description || "No Description. "}
        </Typography>
      </Grid>
    </>
  );
}

export default ListingReviewTitleDescription;
