import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core";

import { BrowserRouter as Router, Link } from "react-router-dom";

import { ListItemButton } from "@mui/material";

import themeObject from "../../util/theme";
function SubList({ title, item, open, onButtonClick }) {
  const theme = useTheme(themeObject);

  return (
    <MuiThemeProvider theme={theme}>
      <List>
        <ListItem>
          <strong>{title}</strong>
        </ListItem>
        {item.map((subItem, index) => (
          <ListItem
            sx={{
              display: "block",
              alignItems: "flex-start",
              textAlign: "left",
              m: 0,
              p: 0,
            }}
          >
            <Link
              to={subItem.url}
              style={{
                textDecoration: "none",
                color:
                  window.location.pathname === subItem.url
                    ? theme.palette.secondary.light
                    : theme.palette.primary.main,
                backgroundColor:
                  window.location.pathname === subItem.url
                    ? theme.palette.primary.main
                    : "white",
                width: "100%",
                borderRadius: 5,
                m: open ? 0 : "auto",
                p: 0,
              }}
            >
              <ListItemButton
                onClick={() => {
                  onButtonClick(subItem.url);
                }}
                sx={{
                  // justifyContent: open ? "initial" : "auto",
                  p: 0,
                  pl: 1,
                  m: 0,
                  // m: open ? 0 : "auto",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    m: 0,
                    p: 0,
                    mr: open ? 3 : 3,
                    justifyContent: "flex-start",
                  }}
                >
                  {window.location.pathname === subItem.url
                    ? subItem.iconClicked
                    : subItem.iconNonClicked}
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    style={{
                      display: open ? "block" : "hidden",
                      height: "auto",
                    }}
                  >
                    {subItem.title}
                  </ListItemText>
                ) : (
                  <></>
                )}
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </MuiThemeProvider>
  );
}

function SideBarList({ list, open, onButtonClick }) {
  return (
    <>
      {list.map((item, index) => (
        <>
          <SubList
            title={item.sectionTitle}
            item={item.items}
            open={open}
            onButtonClick={onButtonClick}
          />
        </>
      ))}
    </>
  );
}

export default SideBarList;
