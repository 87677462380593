import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
  Modal,
  Box,
} from "@material-ui/core";

import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import useGetListing from "../../hooks/useGetListing";
import ListingReviewTitleDescription from "./02_ListingReviewTitleDescription";
import ListingReviewImages from "./01_ListingReviewImages";
import ListingReviewSection from "./ListingReviewSection";
import ListingReviewSchedule from "./04_Schedule";
import ListingReviewAddress from "./05_ListingReviewAddress";
import axios from "axios";
import ListingReviewHeader from "./ListingReviewHeader";
import PreviewAndSubmit from "./PreviewAndSubmit";

function ListingReviewContainer({ id }) {
  const { LoadingLisitng, Listing, Comments } = useGetListing(id);
  const [OpenModal, setOpenModal] = useState(false);

  const handlePublishListing = () => {
    if (Listing) {
      let confirmAction = window.confirm("Confirm approval and publish ?");
      if (confirmAction) {
        const draft_listing = Listing;
        axios
          .post(
            // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/publishSubmittedListing",
            "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/publishSubmittedListing",
            { draft_listing: { ...draft_listing, visibility: "public" } }
          )
          .then((res) => {
            window.location.href = "/listings/to_review";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleDeleteListing = () => {
    if (Listing) {
      let confirmAction = window.confirm(
        "Are you sure you want to delete this listing?"
      );
      if (confirmAction) {
        const draft_listing = Listing;
        axios
          .post(
            // "http://localhost:5004/mobilespacefun/asia-southeast1/api/v2/admin/deleteSubmittedListing",
            "https://asia-southeast1-mobilespacefun.cloudfunctions.net/api/v2/admin/deleteSubmittedListing",
            { draft_listing }
          )
          .then((res) => {
            window.location.href = "/listings/to_review";
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("cancelled");
      }
    }
  };

  return (
    <Grid container>
      <Modal
        open={OpenModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <PreviewAndSubmit listingId={Listing.listingId} Listing={Listing} />
        </Box>
      </Modal>
      <Grid item md={12} lg={12} xl={12} sm={12}>
        <Typography variant="h3">Listing Review:</Typography>
        <Typography>
          Please add your comments if you find something missing, otherwise
          proceed to publish
        </Typography>

        <Stack direction="column" spacing={2} sx={{ justifyContent: "center" }}>
          <ListingReviewHeader
            onApprove={() => {
              handlePublishListing();
            }}
            onReject={() => {
              handleDeleteListing();
            }}
            onSubmitReviews={() => {
              setOpenModal(true);
            }}
          />

          {LoadingLisitng ? (
            <CircularProgress />
          ) : (
            <>
              <ListingReviewSection
                title={"Title and Description"}
                Listing={Listing}
                Comments={Comments}
                content={<ListingReviewTitleDescription Listing={Listing} />}
                key="schedule"
                criteria={
                  <div>
                    <strong>Review criteria : </strong>
                    <br />
                    - Title and description exist <br />
                    - No inappropriate content <br />
                  </div>
                }
              />
              <ListingReviewSection
                title={"Images"}
                Listing={Listing}
                Comments={Comments}
                content={<ListingReviewImages Listing={Listing} />}
                key="schedule"
                criteria={
                  <div>
                    <strong>Review criteria : </strong>
                    <br />
                    - At least one image <br />
                    - No inappropriate content <br />- Images match description.
                  </div>
                }
              />
              <ListingReviewSection
                title={"Schedule"}
                Listing={Listing}
                Comments={Comments}
                content={<ListingReviewSchedule Listing={Listing} />}
                key="schedule"
                criteria={
                  <div>
                    <strong>Review criteria</strong>
                    <br />- At least one day available.
                  </div>
                }
              />
              <ListingReviewSection
                title={"Address and Location"}
                Listing={Listing}
                Comments={Comments}
                content={<ListingReviewAddress Listing={Listing} />}
                key="schedule"
                criteria={
                  <div>
                    <strong>Review criteria</strong>
                    <br />- Make sure that the adress is correct.
                    <br />- The address is detailed and easy to reach.
                  </div>
                }
              />
              <ListingReviewSection
                title={"Workspace types and cost"}
                Listing={Listing}
                Comments={Comments}
                content={<ListingReviewAddress Listing={Listing} />}
                key="schedule"
                criteria={
                  <div>
                    <strong>Review criteria</strong>
                    <br />- Make sure that the adress is correct.
                    <br />- The address is detailed and easy to reach.
                  </div>
                }
              />

              <ListingReviewSection
                title={"Amenities"}
                Listing={Listing}
                Comments={Comments}
                content={<ListingReviewAddress Listing={Listing} />}
                key="schedule"
                criteria={
                  <div>
                    <strong>Review criteria</strong>
                    <br />- Make sure that the adress is correct.
                    <br />- The address is detailed and easy to reach.
                  </div>
                }
              />
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ListingReviewContainer;
