import { ImageList, ImageListItem } from "@material-ui/core";

import React from "react";

function ListingReviewImages({ Listing }) {
  return (
    <>
      <ImageList cols={5} rowHeight={200}>
        {Listing.images &&
          Listing.images.map((item) => (
            <ImageListItem>
              <img
                style={{ borderRadius: 10 }}
                src={`${item.url}`}
                alt="Image"
              />
            </ImageListItem>
          ))}
      </ImageList>
    </>
  );
}

export default ListingReviewImages;
